<template>
  <div class="studentDisplay">
    <div class="tea-search">
      <el-form ref="form" :inline="true" class="demo-form-inline">
        <!-- 查询输入框 -->
        <el-form-item v-show="tab_pane_index!=4">
          <div class="search-text" style="width:300px">
            <el-input placeholder="请输入关键字查询" v-model="search_data.mc" class="input-with-select">
            </el-input>
          </div>
        </el-form-item>
        <el-form-item class="fr" v-show="tab_pane_index!=4">
          <el-button icon="el-icon-search" type="primary" class="fl tea-btn" @click="handelSearchAll(search_data.bjmc)">查 询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="class-box">
      <el-tabs type="border-card" v-model="tab_pane_index" @tab-click='tabClick()'>
        <el-tab-pane label="学生列表" name="1">
          <div class="table">
            <el-table stripe border :data="tableData" height="calc(100% - 40px)">
              <el-table-column prop="index" label="序号" align="center" />
              <el-table-column prop="yhm" label="登陆账号" align="center" />
              <el-table-column prop="xm" label="姓名" align="center" />
              <el-table-column prop="yhid" label="学生编号" align="center" />
              <!-- <el-table-column prop="xjh" label="学籍号" align="center" /> -->
              <el-table-column prop="Gender" label="性别" align="center" />
              <el-table-column prop="bzrsjh" label="备注" align="center" />
            </el-table>
            <div class="tea-pagination">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pages.currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pages.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pages.total">
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="分组管理" name="2" v-if="ywjx==1">
          <div class="stu-group">
            <group :tab_pane_index='2' :class_type='class_type' ref="group" @groupNumChange='groupNumChange'></group>
          </div>
        </el-tab-pane>
        <el-tab-pane label="历史分组管理" name="3" v-if="ywjx==1">
          <div class="stu-group">
            <group :tab_pane_index='3' ref="historyGroup"></group>
          </div>
        </el-tab-pane>
        <el-tab-pane label="课程安排" name="4" v-if="ywjx==1">
          <div class="stu-group">
            <course></course>
          </div>
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>
<script>
// import $bus
import {
  getStudentLisTemporarytApi, getStudentRoutineList, startClassApi,//上课
  endClassApi,//下课
} from '@/api/teacher'
export default {
  components: {
  },
  data() {
    return {
      is_socket: 0,
      group_num: '',
      class_type: 1,//1上课中 0未上课 2已下课
      yhid: this.$store.state.userInfo.yhid,
      zhid: this.$store.state.userInfo.zhid,
      ywjx: this.$route.query.ywjx,
      search_data: {
        bjmc: null
      },
      tab_pane_index: '1',
      tableData: [],
      params: {},
      pages: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      bjid: '',
      bjlx: '',
      bjmc: '',
      // 分组
    }
  },
  created() {
    this.is_socket = this.$store.state.is_socket
  },
  watch: {
    '$store.state.is_socket': function () {
      this.is_socket = this.$store.state.is_socket
    },
  },
  mounted() {
    this.$emit('change-top', [
      { title: '首页', pathName: '/myClass' },
      { title: '我的班级', pathName: '/myClass' },
      { title: '班级学生', pathName: '' },
    ])
    this.bjlx = this.$route.query.lx
    this.bjid = this.$route.query.classId
    this.bjmc = this.$route.query.bjmc,
      this.class_type = this.$route.query.class_type,
      this.getStudentListApiFun()
  },
  methods: {
    groupNumChange(val) {
      this.group_num = val
    },
    handleSizeChange(val) {
      this.pages.currentPage = 1;
      this.pages.pageSize = val;
      this.getStudentListApiFun()
    },
    //  -------------------------------------分页器
    handleCurrentChange(val) {
      this.pages.currentPage = val;
      this.getStudentListApiFun()
    },
    // 分页查询学生
    getStudentListApiFun(data) {
      this.params.currentPage = this.pages.currentPage
      this.params.pageSize = this.pages.pageSize
      this.params.bjid = this.bjid
      this.params.yhid = this.$store.state.userInfo.yhid
      this.params.xm = this.search_data.mc
      data = this.params
      let api = getStudentRoutineList
      if (this.bjlx == 2) {
        api = getStudentLisTemporarytApi
      }
      api(data).then(res => {
        if (res.result) {
          this.pages.total = res.result.total
          res.result.list.forEach((item, index) => {
            item.index = (this.pages.currentPage - 1) * this.pages.pageSize + index + 1
            if (item.xb == 1) {
              item.Gender = '男'
            } else if (item.xb == 2) {
              item.Gender = '女'
            } else if (item.xb == 9) {
              item.Gender = '未选择性别'
            } else {
              item.Gender = '未知'
            }
          })
          this.tableData = res.result.list
        }
      })
    },
    handelSearch() {
      this.pages.currentPage = 1;
      this.getStudentListApiFun()
    },
    // 点击查询
    handelSearchAll() {
      this.judgeHandelSearch()
    },
    // 判断调用哪个
    judgeHandelSearch() {
      if (this.tab_pane_index == 1) {
        this.handelSearch()
      } else if (this.tab_pane_index == 2) {
        this.$refs.group.parentSearch(this.search_data.mc);
      } else if (this.tab_pane_index == 3) {
        this.$refs.historyGroup.parentSearch(this.search_data.mc);
      } else if (this.tab_pane_index == 4) {
      }
    },
    //切换tab栏
    tabClick() {
      this.search_data.mc = '';
      this.judgeHandelSearch()
    },
    // ----------------
    // 点击新增小组
    addGroup() {
      this.$refs.group.editGroup(1);
    },
    // 随机分组
    randomGroup() {
      this.$refs.group.randomGroup();
    },
    // 上课
    classBegin(result) {
      if (this.group_num < 1) {
        this.$message.error('您还没有给学生分组，暂时无法上课')
        return false
      }
      if (!this.is_socket) {
        this.$message.error('学生平板已掉线，请重新连接')
        return false
      }
      const data = {
        bjid: this.bjid,
        bjmc: this.bjmc,
        cjr: this.yhid,
        xgr: this.yhid,
      }
      startClassApi(data).then(res => {
        this.$message.success('已上课')
        this.class_type = 1;
        const query = JSON.parse(JSON.stringify(this.$route.query));
        query.pmzt = this.class_type == 1 ? true : false;
        query.class_type = 1
        this.$router.push({ query: query })
        this.$Bus.$emit('class_change', { type: 1, bjid: this.bjid })
      })
    },
    // 下课
    classEnd(result) {
      if (!this.is_socket) {
        this.$message.error('学生平板已掉线，请重新连接')
        return false
      }
      const data = {
        bjid: this.bjid,
        bjmc: this.bjmc,
        cjr: this.yhid,
        xgr: this.yhid,
      }
      endClassApi(data).then(res => {
        this.$message.success('已下课')
        this.class_type = 2;
        const query = JSON.parse(JSON.stringify(this.$route.query));
        query.pmzt = this.class_type == 1 ? true : false;
        query.class_type = 2
        this.$router.push({ query: query })
        this.$Bus.$emit('class_change', { type: 2, bjid: this.bjid })
      })
    }
  },

}
</script>
<style lang="scss" scoped>
.studentDisplay {
  height: 100%;
  position: relative;
}
.tea-search{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.class-box {
  height: calc(100% - 75px);
  :deep(.el-tabs) {
    height: 100%;
  }
  :deep(.el-tabs__content) {
    // background-color: #eee;
    height: calc(100% - 40px);
  }
 :deep(.el-tab-pane) {
    height: 100%;
    .table {
      height: 100%;
      .tea-pagination {
        margin-top: 10px;
      }
    }
  }
  :deep(.stu-group) {
    height: 100%;
  }
}
</style>